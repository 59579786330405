import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import Entry from "../views/Entry.vue"
import Settings from "../views/Settings.vue"
import Account from "../views/Account.vue"
import AccountLoginCode from "../views/AccountLoginCode.vue"

Vue.use(VueRouter);

const routes = [
  { path: '/account', component: Account },
  { path: '/logincode', component: AccountLoginCode },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/entry",
    name: "Entry",
    component: Entry,
    props: true,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/guide",
    name: "Guide",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("../views/Guide.vue")
  },
  /*  
    {
      path: "/new",
      name: "NewEntry",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import("../views/NewEntry.vue")
    },
  */
  /*  {
    path: "/new2",
    name: "NewEntry2",
    component: () =>
      import("../views/NewEntry2.vue")
  }
*/
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
