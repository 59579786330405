import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from "./router"

Vue.config.productionTip = false

import UUID from "vue-uuid"
Vue.use(UUID)

import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
Vue.use(VueTelInputVuetify, {vuetify});

import { store } from "./store/store.js";

import cloud from "./code/Cloud.js"
Vue.use(cloud, store)


new Vue({
  store: store,
  vuetify,
  router,
  render: h => h(App),
  
  beforeCreate: function() {
    this.$store.dispatch("init")
//    this.$cloud.setBob(45)
  },

  created() {
  }
}).$mount("#app")



if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/sw.js').then(function(registration) {
      // Registration was successful
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function(err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err)
    });
  });
}