<template>
  <div>
    <v-app-bar dense color="white" app>
      <v-btn @click="$router.back()" depressed small icon>
        <v-icon>arrow_back</v-icon>
      </v-btn>

      <v-toolbar-title>Settings</v-toolbar-title>
    </v-app-bar>

    <v-main app>
      <v-list>


        <v-list-item two-line>
          <v-list-item-action>
            <v-icon>mdi-format-size</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Font size ({{ fontSize }})</v-list-item-title>
            <v-list-item-subtitle>
              <v-slider
                v-model="fontSize"
                min="8"
                max="21"
                step="0.5"
                :ticks="true"
                @change = "setFontSize(fontSize)"
              ></v-slider>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>


        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-human-male-child</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              >Offer a walk-through on a new entry</v-list-item-title
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-switch
              v-model="guideOffer"
              @change = "setGuideOffer(guideOffer)"
            ></v-switch>
          </v-list-item-action>
        </v-list-item>


      </v-list>
    </v-main>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  data: function() {
    return this.$store.getters.settings;
  },
  methods: {
    ...mapActions(["setFontSize", "setGuideOffer"])
  }
};
</script>