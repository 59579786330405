<template>
  <div>
	<div v-for="(paragraph, index) in paragraphs" v-bind:key="index">
		<paragraph 
			ref="paragraphComps" 
			:speaker="paragraph.speaker" 
			:text="paragraph.text" 
			@deleted="deleteParagraph(index)" 
			@pressedEnter="pressedEnter(index)" 
			@pressedBackspace = "pressedBackspace(index)" 
			@changed="data => changed(index, data)"
		></paragraph>
	</div>

	<div style="height:16px"></div>

  </div>
</template>


<script>
import Paragraph from './Paragraph.vue'

export default {
	components: {
	  Paragraph
	},
	data: function() {
		return {
			paragraphs: [
			]
		}
	},
	methods: {
		deleteParagraph(index) {
			this.paragraphs.splice(index, 1)

			for(let i in this.paragraphs) {
				this.$refs["paragraphComps"][i].setText(this.paragraphs[i].text)
				this.$refs["paragraphComps"][i].setSpeaker(this.paragraphs[i].speaker)
			}
		},
		makeNew() {
			this.paragraphs = [{speaker:"Me", text:""}]
			this.$nextTick(()=>{
				this.$refs["paragraphComps"][0].setText(this.paragraphs[0].text)
				this.$refs["paragraphComps"][0].setSpeaker(this.paragraphs[0].speaker)
				this.$refs["paragraphComps"][0].focus()
			})
		},

		pressedBackspace(index) {
			console.log("ConversationEditor. Backspace was pressed at index " + index)

			if (index == 0) return	// can't delete the first paragraph

			this.$nextTick(()=>{
				let position = this.paragraphs[index-1].text.length

				this.paragraphs[index-1].text = this.paragraphs[index-1].text + this.paragraphs[index].text
				this.deleteParagraph(index)
				this.$refs["paragraphComps"][index-1].focus(position)
			})
		},

		pressedEnter(index) {
			let newParagraph = {
				speaker: this.paragraphs[index].speaker == "Me" ? "God" : "Me",
				text: ""
			}

			this.paragraphs.splice(index+1, 0, newParagraph)

			this.$nextTick(()=>{
				for(let i = index; i < this.paragraphs.length; i++) {
					this.$refs["paragraphComps"][i].setText(this.paragraphs[i].text)
					this.$refs["paragraphComps"][i].setSpeaker(this.paragraphs[i].speaker)
				}
				this.$refs["paragraphComps"][index+1].focus()
			})
		},
		mergeIntoText() {
			var text = "";
			for(let i in this.paragraphs) {
				if (text != "") text += "\n"
				text += this.paragraphs[i].speaker + ": " + this.paragraphs[i].text
			}
			return text
		},
		changed(index, data) {
			this.paragraphs[index].speaker = data.speaker
			this.paragraphs[index].text = data.text
			this.$emit("textChanged", this.mergeIntoText());
		},
		parseSpeakerAndText(text) {
			let firstColonAt = text.indexOf(":")
			let firstSpaceAt = text.indexOf(" ")
			if ((firstSpaceAt < 0 && firstColonAt > 0) || (firstColonAt > 0 && firstSpaceAt > firstColonAt)) {
				return {
					speaker: text.substring(0, firstColonAt),
					text: text.substring(firstColonAt+1).trim()
				}
			} else {
				return {
					speaker: "Other",
					text: text.trim()
				}
			}
		},
		setText(text) {
			var paras = text.split(/\r?\n/)
			this.paragraphs = []
			for(let i in paras) {
				if (paras[i].trim() != "") {
					this.paragraphs.push(this.parseSpeakerAndText(paras[i]))
				}
			}
			this.$nextTick(()=>{
				for(let i in this.paragraphs) {
					this.$refs["paragraphComps"][i].setText(this.paragraphs[i].text.trim())
					this.$refs["paragraphComps"][i].setSpeaker(this.paragraphs[i].speaker.trim())
				}
			})
		},
	},
}
</script>

<style scoped>

</style>