<template>
  <div>
      <v-app-bar dense color="white" elevate-on-scroll app >

        <v-btn @click="$router.go(-1)" depressed small icon>
            <v-icon>arrow_back</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn @click="showConfirmDelete = true" icon depressed small v-if="!empty" class="ml-4">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>

        <v-menu offset-y>
          
          <template v-slot:activator="{ on, attrs }">
              <v-btn icon depressed small class="ml-4" v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
          </template>
              
          <v-list>
            <v-list-item @click="openSettings()">
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item>
            <v-list-item @click="this.cursorAt = this.editor.getCursorPosition(); $router.push('guide')">
              <v-list-item-title>How to Hear From God</v-list-item-title>
            </v-list-item>
          </v-list>

        </v-menu>

      </v-app-bar>


      <v-main class="mt-0 mb-0 ml-3 mr-3">
        <template>
          <conversation-editor ref="editor" @textChanged="contentChanged"></conversation-editor>
        </template>
      </v-main>



      <v-dialog v-model="showConfirmDelete" max-width="290">

        <v-card>
          <v-card-title class="headline">
            Delete this entry?
          </v-card-title>

          <v-card-text>
            Do you want to permanently delete this journal entry?  You can't get it back.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="showConfirmDelete = false">
              Cancel
            </v-btn>

            <v-btn color="green darken-1" text @click="this.delete">
              Delete
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>




    <v-dialog v-model="showGuide" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Walk through?
        </v-card-title>

        <v-card-text>
          Would you like a walk-through on how to hear from God?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1" text @click="this.showGuide = false" ref="no">
            Not now
          </v-btn>

          <v-btn color="green darken-1" text @click="showGuide = false; $router.push('guide')" ref="yes">
            Sure
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>

</template>

<script>
import ConversationEditor from "../components/ConversationEditor";

export default {
  components: {
    ConversationEditor
  },
  props: {
    action: {
      type: String,
      default: "nothing",
      required: false,
    },
    entry: {
      type: [Object],
      default: null,
      required: false,
    },
  },

  data: () => ({
    empty: true,
    showConfirmDelete: false,
    showGuide: false,
    shouldSave: false,
    saveTimer: null,
    editing: false,
    cursorAt: 0,
    pauseListening: true
  }),

  watch: {
    '$route': function(to /*, from */) {
      // react to route changes...
      if (to.name == "Home") {
        if (this.shouldSave) {
          if (this.saveTimer != null) {
            clearTimeout(this.saveTimer)
          }
          if (this.empty) {
            this.$store.dispatch("deleteEntry", this.entryId).then(() => {
              this.$cloud.fastSync()
            })
          } else {
            this.$store.dispatch("updateEntry", {id:this.entryId, text:this.$refs.editor.mergeIntoText()}).then(() => {
              this.$cloud.fastSync()
            })
          }
        }

        clearTimeout(this.saveTimer)
      }
    }
  },


  methods: {
    openSettings: function() {
    //  this.cursorAt = this.editor.getCursorPosition(); 
      this.$router.push('settings')
    },

    contentChanged: function(data) {
      var tagless = data.replace( /(<([^>]+)>)/ig, '').trim();
      this.empty = (tagless == "" || tagless == "Me:" || tagless == "Me:&nbsp;")

      this.shouldSave = true
      if (this.saveTimer != null) {
        clearTimeout(this.saveTimer)
        this.saveTimer = null
      }
      if (!this.empty) {
        this.saveTimer = setTimeout(() => {
          this.$store.dispatch("updateEntry", {id:this.entryId, text:data}).then((found) => {
            this.$cloud.fastSync()
          })
          this.saveTimer = null
        }, 2000)
      }
    },

    delete: function() {
      this.$store.dispatch("deleteEntry", this.entryId).then(() => {
        this.$cloud.fastSync()
      })
      this.$router.go(-1)
      this.confirmDelete = false;
    },
    beforeDestroy: function() {
      clearTimeout(this.saveTimer)    
    },
    getPosition: function(element) {
      var xPosition = 0;
      var yPosition = 0;

      while(element) {
          xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
          yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
          element = element.offsetParent;
      }

      return { x: xPosition, y: yPosition };
    },


  },

  activated() {
    this.pauseListening = true

    if (this.$route.params.action) {
      this.entryId = this.$route.params.entry.id

      if (this.$route.params.action == "view") {
        this.$refs.editor.setText(this.$route.params.entry.text)
        this.editing = false
        this.empty = false
      }

      if (this.$route.params.action == "new") {
        this.$refs.editor.makeNew()

        this.editing = true
        this.empty = true
      }
    }

    this.pauseListening = false
  },

}

</script>


<style scoped>

</style>
