<template>
  <div>
    <v-app-bar dense color="white" app>
      <v-btn @click="$router.back()" depressed small icon>
        <v-icon>arrow_back</v-icon>
      </v-btn>

      <v-toolbar-title>Account</v-toolbar-title>
    </v-app-bar>

    <v-main app>


        <div v-show="this.$cloud.isLoggedIn()">
            <div style="margin-top:80px;max-width:300px; margin-left:auto; margin-right:auto">
            
                <p>You are logged in as {{ userEmail }}.</p>

                <v-btn
                        class = "mt-4"
                        ref = "logoutButton"
                        color="primary"
                        elevation="2"
                        @click="logout"
                    >Log Out</v-btn>

                <div class="pt-4 pb-4"><a href="#" style="text-decoration: none;" @click="showAdvanced = !showAdvanced">advanced
                <v-icon v-show="!showAdvanced">mdi-chevron-down</v-icon>
                <v-icon v-show="showAdvanced">mdi-chevron-up</v-icon>
                </a></div>


                <div :class="{'advanced':true, 'showAdvanced':showAdvanced}" >
                    <p><v-btn @click="fastSync">Fast Sync</v-btn></p>

                    <p><v-btn @click="fullSync">Full Sync</v-btn></p>
                </div>

            </div>
        </div>


        <div v-show="!this.$cloud.isLoggedIn()">

            <div style="margin-top:80px;max-width:300px; margin-left:auto; margin-right:auto">

                <v-form v-model="isValid">

                <p>You are not logged in.</p>
                
                <p>To log in or register, we'll email you a login code.</p>

<div v-if="false">
                <vue-tel-input-vuetify
                    label = "phone number"
                    v-model = "phone"
                    autofocus
                    :preferredCountries = "preferredCountries"
                    @validate = "validate"
                ></vue-tel-input-vuetify>
</div>


                <v-text-field label="email address" v-model="email" :rules="emailRules" type="email" required></v-text-field>


                <v-btn
                    class = "mt-4"
                    ref = "theButton"
                    color="primary"
                    elevation="2"
                    @click="sendIt"
                    :disabled="!isValid"
                >{{ buttonText }}</v-btn>

                </v-form>
            </div>

        </div>
    </v-main>


    <v-snackbar
      v-model="showSnackbar"
      :timeout="snackbarTimeout"
    >
        {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    </div>
</template>

<script>


export default {
    data: function() {
        return {
            showSnackbar: false,
            snackbarTimeout: 2000,
            snackbarText: "",
            phone: "",
            email: "",
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
            ],
            phoneE164: null,
            preferredCountries: ['CA', 'US', 'UK'],
            sendEnabled:false,
            isValid: true,
            buttonText:"Send It",
            showAdvanced:false,
        }
    },

    computed: {
        userEmail() {
            return this.$store.getters.user.email
        }
    },

    methods:{
        fastSync() {
            this.snackbarText = "Saving..."
            this.showSnackbar = true
            this.$cloud.fastSync().then(() => {
                this.snackbarText = "Done saving."
                this.showSnackbar = true
            })
        },

        fullSync() {
            this.snackbarText = "Saving..."
            this.showSnackbar = true
            this.$cloud.fullSync().then(() => {
                this.snackbarText = "Done saving."
                this.showSnackbar = true
            })
        },

        logout() {
            this.snackbarText = "Saving first..."
            this.showSnackbar = true
            this.$cloud.fullSync().then(() => {
                this.snackbarText = "Logging out..."
                this.showSnackbar = true
                this.$cloud.logout().then(() => {
                    this.snackbarText = "You are logged out."
                    this.showSnackbar = true
                })
            })
        },

        async sendIt() {
            if (this.email != "") {
                try {
                    await this.$cloud.sendLoginCodeToEmail(this.email)
                    this.$router.push('logincode')
                } catch(e) {
                    alert("Error: " + e)
                }
            }

            // if (this.phoneE164 != null) {
            //     this.buttonText = "Sending..."
            //     this.sendEnabled = false

            //     try {
            //         await this.$cloud.sendLoginCodeToPhone(this.phoneE164)
            //         this.$router.push('logincode')
            //     } catch(e) {
            //         alert("Error: " + e)
            //     }
            // }
        },
        validate(info) {
            this.sendEnabled = info.isValid
            if (info.isValid) {
                this.phoneE164 = info.number.e164
            } else {
                this.phoneE164 = null
            }
        }
    }
}
</script>


<style>
.advanced {
    height: 0px;
    transition: height 0.250s;
    overflow: hidden;
}

.showAdvanced {
    height:auto;
}
</style>
