<template>
	<div class="paragraph">
		<div class="speaker">

			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn class="button" style="border-color:gray; color:#444444;" :outlined="true" ref="speaker" x-small elevation="0" v-bind="attrs" v-on="on">{{ dataSpeaker }}:</v-btn>
				</template>
				<v-list>
					<v-list-item v-for="(speaker, index) in speakers" :key="index" @click="speakerChanged(speaker)">
						<v-list-item-title>{{ speaker[0] == '[' ? speaker : speaker + ":" }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>

		</div>
		
		<textarea v-model="dataText" @input="keyPressed" @keypress="keyPress" @keydown="keyDown" ref="text"></textarea>
	</div>
</template>


<script>
export default {
		props: {
			speaker: String,
			text: String
		},
		data: function() { return {
			dataSpeaker: this.speaker,  // comes from props
			dataText: this.text,  // comes from props
			speakers: ["Me", "God", "Heading", "Description", "[Delete]"],
			speakerColours: ["#8580cc", "#b17272", "black", "dimgray" ],
		}},
		methods: {
			focus(position) {
				this.$refs.text.focus()
				if (position != undefined) {
					this.$refs["text"].setSelectionRange(position, position)
				}
			},
			speakerChanged: function(newSpeaker) {
				if (newSpeaker == "" || newSpeaker == undefined) return
				this.dataSpeaker = newSpeaker
				if (this.dataSpeaker == "[Delete]") {
					this.$emit('deleted')
					return
				}
				this.styleText()
				this.$nextTick(()=> {
					this.update()
				})
			},
			styleText() {
				var speakerIndex = this.speakers.indexOf(this.dataSpeaker)
				if (speakerIndex >= 0) {
					this.$refs.text.style.color = this.speakerColours[speakerIndex]
				} else {
					this.$refs.text.style.color = "dimgray"
				}
				if (this.dataSpeaker == "Heading") {
					this.$refs.text.style.fontWeight = "bold"
				} else {
					this.$refs.text.style.fontWeight = ""
				}
			},
			getSpeaker() {
				return this.dataSpeaker
			},
			getText() {
				return this.dataText
			},
			setSpeaker(speaker) {
				this.dataSpeaker = speaker
				this.styleText()
			},
			setText(text) {
				this.dataText = text
				this.resize()
			},
			resize() {
				this.$nextTick(()=> {
					// text area first line indent
					let width = this.$refs["speaker"].$el.offsetWidth
					this.$refs["text"].style.textIndent = (width + 6) + "px"

					// textarea height
					this.$refs["text"].style.height = "0px"
					this.$refs["text"].style.height = (this.$refs["text"].scrollHeight)+"px"
				})
			},

			keyDown(e) {
				var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0

				console.log("keyDown ", key, " cursor position ", this.$refs["text"].selectionStart)

				if (key == 8 && this.$refs["text"].selectionStart == 0) { 
					e.preventDefault();
					this.$emit('pressedBackspace')
					return false
				}
			},

			keyPress(e) {
				var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0

				if(key == 13) { 
					e.preventDefault();
					this.$emit('pressedEnter')
					return false
				}
			},

			addSpeaker(colonAt) {
				let speaker = this.dataText.substring(0, colonAt)
				var speakerIndex = this.speakers.indexOf(this.dataSpeaker)
				if (speakerIndex < 0) {
					// this speaker was not already found.
					this.speakers.splice(this.speakers.length-2, 0, [speaker])
				}
				this.dataSpeaker = speaker
				this.styleText()
				this.dataText = this.dataText.substring(colonAt+1)
				this.dataSpeaker = speaker

				this.$nextTick(()=>{
					if (this.$refs["text"].setSelectionRange) { 
							this.$refs["text"].focus(); 
							this.$refs["text"].setSelectionRange(0, 0); 
					} else if (this.$refs["text"].createTextRange) { 
							var range = this.$refs["text"].createTextRange();  
							range.moveStart('character', 0); 
							range.select(); 
					}
					this.update()
				})
			},
			keyPressed() {
				let pos = this.getCursorPos() - 1
				let char = this.$refs["text"].value[pos]

				if (char == undefined) return

				console.log("keyPressed ", char.charCodeAt(0))

				if (char.charCodeAt(0) == 10) {
					this.$refs["text"].value = this.$refs["text"].value.substring(0, pos-1) + this.$refs["text"].value.substring(pos+1)
					this.$emit('pressedEnter')
					return false
				}

				let firstColonAt = this.dataText.indexOf(":")
				let firstSpaceAt = this.dataText.indexOf(" ")
				if ((firstSpaceAt < 0 && firstColonAt > 0) || (firstColonAt > 0 && firstSpaceAt > firstColonAt)) {
					this.addSpeaker(firstColonAt)
					// the above changes will require calling resize(), but we do that down there
				}
				if (this.dataText.length > 0 && this.dataText[0] == " ") {
					this.dataText = this.dataText.substring(1)
				}

				this.resize()
				this.update();
			},
			update() {
				this.$emit('changed', {
					speaker: this.dataSpeaker,
					text: this.dataText
				})
			},
			getCursorPos() {
				let input = this.$refs["text"]
				if ("selectionStart" in input && document.activeElement == input) {
						return input.selectionStart
				}
				else if (input.createTextRange) {
						var sel = document.selection.createRange();
						if (sel.parentElement() === input) {
								var rng = input.createTextRange();
								rng.moveToBookmark(sel.getBookmark());
								for (var len = 0;
												rng.compareEndPoints("EndToStart", rng) > 0;
												rng.moveEnd("character", -1)) {
										len++;
								}
								rng.setEndPoint("StartToStart", input.createTextRange());
								for (var pos = { start: 0, end: len };
												rng.compareEndPoints("EndToStart", rng) > 0;
												rng.moveEnd("character", -1)) {
										pos.start++;
										pos.end++;
								}
								return pos.start;
						}
				}
				return -1;
			}
		},
		mounted: function() {
			this.$refs.text.style.fontSize = this.$store.getters.settings.fontSize + "px"
			this.$refs.speaker.$el.style.fontSize = this.$store.getters.settings.fontSize + "px"
			this.$refs.speaker.$el.style.height = (this.$store.getters.settings.fontSize + 5) + "px"
			this.styleText()
			this.$nextTick(()=>{
				this.resize()
			})
		},
		watch: {
			'$store.state.settings.fontSize': function() {
				console.log("state settings fontsize changed to: ", this.$store.getters.settings.fontSize)
				this.$refs.text.style.fontSize = this.$store.getters.settings.fontSize + "px"
				this.$refs.speaker.$el.style.fontSize = this.$store.getters.settings.fontSize + "px"
				this.$refs.speaker.$el.style.height = (this.$store.getters.settings.fontSize + 5) + "px"
				this.$nextTick(()=>{
					this.resize()
				})
			},
		}
}
</script>

<style scoped>
div.speaker {
	height:0px;
	vertical-align: top;
}
div.paragraph {
	margin-bottom:0px;
}
textarea {
	resize: none;
	overflow: hidden;
	min-height: 20px;
	width:100%;
	border-width:0px;
	text-indent: 40px;
}
textarea:focus {
	outline-width: 0;
}
.v-btn {
	text-transform: none;
}
button {
	padding-top:1px !important;
}
</style>

<style>
</style>