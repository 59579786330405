<template>
  <div id="home">
    <!-- the floating "New" button in the bottom right corner -->
    <v-btn
      @click="newEntry()"
      style="bottom: 20px"
      color="yellow"
      fixed
      bottom
      right
      fab
    >
      <v-icon>add</v-icon>
    </v-btn>

    <v-app-bar app clipped-left color="blue accent-2">
      <div id="logo" @click="drawer = !drawer"></div>

      <v-toolbar-title>Words of Life</v-toolbar-title>
      <!--
      <v-text-field
        solo-inverted
        flat
        hide-details
        label="Search"
        prepend-inner-icon="search"
      ></v-text-field>
      -->
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app clipped color="grey lighten-4">
      <v-list dense nav>

        <v-list-item link @click="newEntry()">
          <v-list-item-icon>
            <v-icon>add</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>New Journal Entry</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item link @click="$router.push('settings')">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item link @click="$router.push('account')">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item link @click="$router.push('guide')">
          <v-list-item-icon>
            <v-icon>mdi-human-male-child</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>How to Hear From God</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


      </v-list>
    </v-navigation-drawer>


    <v-main class="ma-4" app>
      <v-card
        class="mb-4 entryIntro"
        :elevation="4"
        v-for="entry in entries"
        :key="entry.id"
        @click="openEntry(entry)"
      >
        <v-card-subtitle class="pt-2 pb-2 subtitle-2">{{ toEnglishTime(entry.creationTime) }}</v-card-subtitle>
        <v-card-text class="entryText" v-html="shortenText(entry.text)"></v-card-text>
      </v-card>



      <v-slide-y-transition>
            <v-container fixed fill-height fluid v-if="this.$store.state.entriesLoaded && entries.length == 0" transition="fade-transition">
              <v-row align="center" justify="center">
                <v-col>
                  <v-alert prominent type="info" transition="scale-transition" icon="mdi-emoticon-excited-outline">
                    <p>Welcome!</p>
                    <p>Click the yellow button down there to get started.</p>
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
      </v-slide-y-transition>



    </v-main>



  </div>
</template>


<style scoped>
#logo {
  background-image: url(/img/icons/64.png);
  width: 52px;
  height: 22px;
  background-size: contain;
}

.entryIntro {
  max-height: 129px;
  overflow-y: hidden;
}

.entryText {
  line-height: 1.2;
}
</style>


<script>
import { uuid as uuidLibrary } from 'vue-uuid';

export default {
  name: "Home",

  data: () => {
    return {
      drawer: null,
    };
  },

  computed: {
    entries: function () {
      var entries = this.$store.getters.undeletedEntries
      entries.sort((a, b) => {
        return b.creationTime - a.creationTime;
      });
      return entries
    },
  },

  watch: {
  },

  methods: {
    openEntry: function (entry) {
      this.$router.push({ name: "Entry", params: { action:"view", entry: entry } });
    },
    newEntry:  function () {
      var id = uuidLibrary.v4();
      var entry = {id:id, text:""}
      console.log("Created entry", entry)
      this.$store.dispatch("addEntry", entry);
      this.$router.push({ name: "Entry", params: { action:"new", entry:  entry} });
    },
    shortenText: function(text) {
        var short = text.substr(0, 300);
        short = short.replaceAll("\n\n", "\n");
        return short;
    },
    toEnglishTime: function (creationTime) {
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var dows = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

        var now = new Date();
        var created = new Date(creationTime);

        var hours = created.getHours();
        var ampm = "AM";

        if (hours == 12) ampm = "PM";
        if (hours > 12) {
            hours -= 12;
            ampm = "PM";
        }

        var minutes = created.getMinutes();
        if (minutes < 10) minutes = "0" + minutes;

        if (now.getFullYear() == created.getFullYear() && now.getMonth() && created.getMonth() && now.getDate() == created.getDate()) {
            // today
            return "Today at " + hours + ":" + minutes + ampm;
        }

        var dateStr = dows[created.getDay()] + ", " + months[created.getMonth()] + " " + created.getDate() + ", " + hours + ":" + minutes + ampm;
        return dateStr;
    }
  },

  mounted: function () {
    // this.$vuetify.theme.dark = true
  }

};
</script>


<style scoped>
.entryText {
  white-space: pre-wrap;
}
</style>