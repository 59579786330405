import Vue from 'vue'
import Vuex from 'vuex'

import { uuid as uuidLibrary } from 'vue-uuid';

import { set, get } from 'idb-keyval'


Vue.use(Vuex);

export const store = new Vuex.Store({

	state: {
		entries: [],
		entriesLoaded: false,

		settings: {      
			fontSize: 11,
			guideOffer: true,
			lastModifiedTime: 0
		},
		settingsLoaded: false,

		user: {
			email: "",
			authToken: null,
		},
		lastSyncTime: 0,

		lastUpdateTime: 0,  // this is updated every time we make a change, so Vue knows to update stuff
	},

	getters: {
		entriesAsObject: (state) => {
			var object = {}
			state.entries.forEach(entry => {
				object[entry.id] = entry
			})
			return object
		},
		user: state => state.user,
		authToken: state => state.user.authToken,
		settings: state => state.settings,
		entries: state => state.entries,
		lastSyncTime: state => state.lastSyncTime,
		undeletedEntries: state => state.entries.filter(entry => entry.deleted !== true),
	},

	mutations: {
		init: function(state) {

		},
		updateEntry: function(state, entry) {
			var found = false
			var foundId = null
			for(var i=0; i<state.entries.length; i++) {
				if (state.entries[i].id == entry.id) {
					state.entries[i].text = entry.text
					state.entries[i].lastModifiedTime = Date.now()
					found = true
					foundId = i
					break
				} else {
					// console.log("updateEntry not found ", state.entries[i].id, entry.id)
				}
			}
			return found
		},
		addEntry: function(state, entry) {
			state.entries.splice(0, 0, {
				text: entry.text,
				id: entry.id,
				creationTime: Date.now(),
				lastModifiedTime: Date.now(),
			})
		},

		purgeAllEntries: function(state) {
			state.entries = []
		},

		deleteEntry: function(state, entryId) {
			for(var i=0; i<state.entries.length; i++) {
				if (state.entries[i].id == entryId) {
					Vue.set(state.entries[i], "deleted", true)  // Vue doesn't detect this change otherwise
					state.entries[i].text = ""
					state.entries[i].lastModifiedTime = Date.now()
				}
			}
			state.lastUpdateTime = Date.now()
		},
		setFontSize: function(state, fontSize) {
			state.settings.fontSize = fontSize
			state.settings.modifiedTime = (new Date()).getTime()
		},
		setGuideOffer: function(state, guideOffer) {
			state.settings.guideOffer = guideOffer
			state.settings.modifiedTime = (new Date()).getTime()
		},
		setUserEmail: function(state, email) {
			state.user.email = email
		},
		setUserAuthToken: function(state, authToken) {
			state.user.authToken = authToken
		},
		setLastSyncTime: function(state, lastSyncTime) {
			state.lastSyncTime = lastSyncTime
		},
		setServerIds: function(state, serverIds) {
			for(var e=0; e<state.entries.length; e++) {
				for(var clientId in serverIds) {
					if (clientId == state.entries[e].id) {
						state.entries[e].serverId = serverIds[clientId]
					}
				}
			}
		},


		purgeEntryIds: function(state, purgeEntryIds) {
			for(var i=0; i<state.entries.length; i++) {
				if (purgeEntryIds.includes(state.entries[i].id)) {
					state.entries.splice(i, 1)
					i--
				}
			}
		},

		addServerEntries: function(state, serverEntries) {

			serverEntries.forEach(serverEntry => {
				var foundAt = null
				for(var i=0; i<state.entries.length; i++) {
					var entry = state.entries[i]
					if (serverEntry.uuid && serverEntry.uuid == entry.id) {
						foundAt = i
					}
					if (serverEntry.serverId && serverEntry.serverId == entry.serverId) {
						foundAt = i
					}
				}
				if (foundAt != null) {
					// update it
					if (serverEntry.deleted) {
						state.entries.splice(foundAt, 1)
					} else {
						state.entries[foundAt].text = serverEntry.text
						state.entries[foundAt].lastModifiedTime = serverEntry.lastModifiedTime
						state.entries[foundAt].creationTime = serverEntry.creationTime
						state.entries[foundAt].id = serverEntry.id
						state.entries[foundAt].serverId = serverEntry.serverId

						if (serverEntry.id == undefined || serverEntry.id == null || serverEntry.id == "") {
							state.entries[foundAt].id = uuidLibrary.v4()
						}
					}
				} else {
					// create it
					if (!serverEntry.deleted) {
						var e = {}
						e.text = serverEntry.text
						e.lastModifiedTime = serverEntry.lastModifiedTime
						e.creationTime = serverEntry.creationTime
						e.id = serverEntry.id
						e.serverId = serverEntry.serverId
	
						if (e.id == undefined || e.id == null || e.id == "") {
							e.id = uuidLibrary.v4()
						}
						state.entries.push(e)
					}
				}
			})
		},
	},

	actions: {
		init: function(context) {
			get("entries").then(entryData => {
				context.state.entries.length = 0;
				for(var i in entryData) {
					var e = {
						id: entryData[i].id,
						text: entryData[i].text,
						serverId: entryData[i].serverId || "",
						creationTime: entryData[i].creationTime,
						lastModifiedTime: entryData[i].lastModifiedTime || entryData[i].creationTime
					};
					if (entryData[i].deleted === true) e.deleted = entryData[i].deleted
					context.state.entries.push(e);
				}
				context.state.entriesLoaded = true
			});
			get("user").then(userData => {
				if (userData) {
					context.state.user.email = userData.email || "..."
					context.state.user.authToken = userData.authToken || null
				} else {
					context.state.user.email = "..."
					context.state.user.authToken = null
				}
			});
			get("settings").then(settingsData => {
				if (settingsData) {
					context.state.settings.fontSize = settingsData.fontSize || 11;
					context.state.settings.guideOffer = !!settingsData.guideOffer;
					context.state.settings.lastModifiedTime = settingsData.lastModifiedTime || 0;
				} else {
					context.state.settings.fontSize = 11;
					context.state.settings.guideOffer = true;
					context.state.settings.lastModifiedTime = 0;
				}
				context.state.settingsLoaded = true
			});
			get("lastSyncTime").then(lastSyncTime => {
				if (lastSyncTime) {
					context.state.lastSyncTime = lastSyncTime
				}
			})
		},
		deleteEntry: async function(context, entryId) {
			context.commit("deleteEntry", entryId)
			await set("entries", context.state.entries)
		},
		purgeAllEntries: async function(context) {
			context.commit("purgeAllEntries")
			await set("entries", context.state.entries)
		},
		updateEntry: async function(context, entry) {
			context.commit("updateEntry", entry)
			await set("entries", context.state.entries)
		},
		addEntry: async function(context, entry) {
			context.commit("addEntry", {id:entry.id, text:entry.text})
			await set("entries", context.state.entries)
		},
		setFontSize: async function(context, fontSize) {
			context.commit("setFontSize", fontSize)
			await set("settings", context.state.settings)
		},
		setAddSpeaker: function(context, addSpeaker) {
			return context.commit("setAddSpeaker", addSpeaker)
		},
		setGuideOffer: async function(context, guideOffer) {
			context.commit("setGuideOffer", guideOffer)
			await set("settings", context.state.settings)
		},
		setUserEmail: async function(context, email) {
			context.commit("setUserEmail", email)
			set("user", context.state.user)
		},
		setUserAuthToken: async function(context, authToken) {
			context.commit("setUserAuthToken", authToken)
			set("user", context.state.user)
		},
		logout: function(context) {
			return Promise.all([
				context.commit("setUserAuthToken", null),
				context.commit("setUserEmail", null)
			])
		},
		setLastSyncTime: async function(context, lastSyncTime) {
			context.commit("setLastSyncTime", lastSyncTime)
			set("lastSyncTime", context.state.lastSyncTime)
		},
		setServerIds: async function(context, serverIds) {
			context.commit("setServerIds", serverIds)
			set("entries", context.state.entries)
		},
		addServerEntries: async function(context, entries) {
			context.commit("addServerEntries", entries)
			await set("entries", context.state.entries);
		},
		purgeEntryIds: async function(context, entries) {
			context.commit("purgeEntryIds", entries)
			await set("entries", context.state.entries)
		},

	}
   
})

