// import Vue from 'vue'

class Cloud {
	#vue = null
	#store = null
	#apiURL = process.env.VUE_APP_ROOT_API + "/v1"
	// online = window.navigator.onLine

	constructor(vue, store) {
		this.#vue = vue
		this.#store = store

		// window.addEventListener('offline', () => {
		//     this.online = window.navigator.onLine
		//     console.log("You lost connection.")
		//     Vue.set(this, "online", window.navigator.onLine)
		// });

		// window.addEventListener('online', () => {
		//     this.online = window.navigator.onLine
		//     console.log("You are now back online.")
		//     Vue.set(this, "online", window.navigator.onLine)
		// });
	}

	
	confirmCode(loginCode) {
		return new Promise(resolve => {
			var options = {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: this.#store.getters.user.email,
					loginCode: loginCode
				}),
			}
			var url = this.#apiURL + "/login" 

			fetch(url, options).then(data=>data.json()).then(async response => {
				if (response.success && response.authToken) {
					await this.#store.dispatch("purgeAllEntries")
					await this.#store.dispatch("setUserAuthToken", response.authToken)
					await this.fullSync()
				}
				resolve()
			})
		   
	
		})
	}

	fullSync() {
		return this.sync(0)
	}

	fastSync() {
		return this.sync(this.#store.getters.lastSyncTime || 0)
	}

	sync(lastSyncTime) {
		return new Promise(resolve => {
			let syncStartTime = (new Date()).getTime()

			if (!this.isLoggedIn()) {
				resolve()
				return
			}

			var changedEntries = []
			var purgeEntryIds = []

			this.#store.getters.entries.forEach(entry => {
				if (entry.lastModifiedTime >= lastSyncTime) {
					changedEntries.push({
						id: entry.id,
						text: entry.deleted !== true ? entry.text : "",
						creationTime: entry.creationTime,
						lastModifiedTime: entry.lastModifiedTime,
						serverId: entry.serverId || "",
						deleted: !!entry.deleted,
					})
					if (entry.deleted === true) {
						purgeEntryIds.push(entry.id)
					}
				} else {
					// console.log("No, this entry ("+entry.lastModifiedTime+") is not greater than " + lastSyncTime)
				}
			})

			var options = {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + this.#store.getters.authToken,
				},
				body: JSON.stringify({
					lastSyncTime:lastSyncTime,
					entries:changedEntries,
				}),
			}
			var url = this.#apiURL + "/sync" 

			fetch(url, options).then(data=>data.json()).then(response => {
				this.#store.dispatch("setLastSyncTime", syncStartTime)
				this.#store.dispatch("setServerIds", response.newIds)
				this.#store.dispatch("addServerEntries", response.entries)
				this.#store.dispatch("purgeEntryIds", purgeEntryIds)
				resolve()
			})
		})
	}

	logout() {
		return Promise.all([
			this.#store.dispatch("setUserEmail", null),
			this.#store.dispatch("setUserAuthToken", null),
			this.#store.dispatch("setLastSyncTime", 0),
			this.#store.dispatch("purgeAllEntries"),
		])
	}


	async sendLoginCodeToEmail(email) {
		this.#store.dispatch("setUserEmail", email)
		this.#store.dispatch("setUserAuthToken", null)

		var options = {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: email
			}),
		}
		var url = this.#apiURL + "/sendCode" 

		fetch(url, options).then(data=>data.json())
	}


	isLoggedIn() {
		return this.#store.getters.user.authToken != null
	}

}


export default {

	install (vue, store) {
		vue.prototype.$cloud = new Cloud(vue, store)
	}

}