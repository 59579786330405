<template>
  <div>
    <v-app-bar dense color="white" app>
      <v-btn @click="$router.back()" depressed small icon>
        <v-icon>arrow_back</v-icon>
      </v-btn>

      <v-toolbar-title>Login Code</v-toolbar-title>
    </v-app-bar>

    <v-main app>

            <div style="margin-top:80px;max-width:300px; margin-left:auto; margin-right:auto">

                <v-text-field
                    label = "The login code"
                    v-model = "code"
                    autofocus
                    type = "number"
                ></v-text-field>


                <v-btn
                    class = "mt-4"
                    color="primary"
                    elevation="2"
                    @click="sendIt"
                >OK</v-btn>

            </div>

    </v-main>

    </div>
</template>

<script>


export default {
    data: function() {
        return {
            code: "",
        }
    },

    computed: {
    },

    methods: {
        async sendIt() {
            try {
                await this.$cloud.confirmCode(this.code)
                this.$router.back()
                // this.$router.push('account')
            } catch(e) {
                alert("Error: " + e)
            }
        },
    }
}
</script>
